




























































































































































.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #7bc514;
}
.contact {
  width: 100%;
  max-width: 1368px;
  position: relative;
  padding: 1.25rem;
  margin: 20px auto;
  h5 {
    color: #ffffff;
    margin-bottom: 15px;
  }
  p {
    color: #ffffff;
    margin-bottom: 15px;
    line-height: 120%;
  }
  .form-contact {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 0 auto;
    background-color: rgba(49, 52, 58, 1) !important;
    border-radius: 10px;
    padding: 10px;
    .item-input {
      width: 100%;
      p {
        color: #ffffff;
        margin-bottom: 5px;
        margin-top: 20px;
      }
      .form-control {
        border: 1px solid rgb(45, 48, 53);
        background-color: rgba(84, 86, 88, 0.452);
        border-left: none;
        border-radius: 15px;
        color: rgb(212, 250, 77);
        &:focus {
          background: none;
          border: 1px solid rgb(123, 197, 20);
          outline: none;
          box-shadow: none;
          color: rgb(212, 250, 77);
        }
      }
      fieldset {
        margin-bottom: 0px;
        .custom-control-label {
          color: #ffffff;
        }
      }
    }
    .group-btn {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      button {
        margin: 0px 10px;
      }
      .btn-send {
        color: #f5f6f7;
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-color: #5da000;
        background-image: conic-gradient(from 1turn, #458802, #7bc514);
        border-radius: 6.25rem;
        font-weight: bold;
        border: none;
      }
      .btn-reset {
        color: #f5f6f7;
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-color: #5da000;
        background-image: conic-gradient(from 1turn, #880202, #c51414);
        border-radius: 6.25rem;
        font-weight: bold;
        border: none;
      }
    }
  }
}
